import { Link } from "@StarberryUtils";
import { get } from 'lodash';
import React, { useState, useEffect } from "react";
import { Row, Col, Container, Navbar, Nav } from 'react-bootstrap';
import NewsCard from "../NewsCard/NewsCard"
import Subscribe from "../Subscribe/Subscribe"
import "./NewsPost.scss";
//import ScrollAnimation from 'react-animate-on-scroll';

import { NewsDate } from "../../common/site/functions";


const NewsPost = (props) => {

//console.log(props.data)
    // const chunkSize = 6;
    // const arr = props.data
    // const groups = arr.map((e, i) => {
    //     return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
    // }).filter(e => { return e; });

    return (
        <div className="news-posts components-News-NewsPost-NewsPost">
            <Container>
                <Row>
                    <Col lg={12}>
                    <div class="card-columns">
                    {props.data?.map((news, i) => {


let detailUrl = `${get(news, 'Category[0].URL', 'news')}/${news.URL}`
if (props.tag !== 'parent')
    detailUrl = `../${detailUrl}`;

const image_url = news.Tile_Image.url;

let processedImages = JSON.stringify({});
if (news?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = news.imagetransforms.Tile_Image_Transforms;
}
let imageSize = "property-news.Tile_Image.tile_2"
let myclassname = ""
if (news?.Image_Type && "Text_Image" == news.Image_Type) {
    imageSize = "property-news.Tile_Image.tile_3"
    myclassname="textimage"
}
    return (
        <div class="card">
                <Link to={detailUrl}><NewsCard
                newsImg={`${news.Tile_Image.url}`}
                schedule = {NewsDate(news.Date)}
                newsTitle = {news.Title}
                id={news.id}
                processedImages={processedImages}
                img={imageSize}
                className={myclassname}
                /></Link>
        </div>
    )
})}
</div>
  {/* <div class="card">

    <div class="card-body">
      <h5 class="card-title">Card title that wraps to a new line</h5>
      <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
    </div>
  </div>
  <div class="card p-3">
    <blockquote class="blockquote mb-0 card-body">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Someone famous in <cite title="Source Title">Source Title</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card">

    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
    </div>
  </div>
  <div class="card bg-primary text-white text-center p-3">
    <blockquote class="blockquote mb-0">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.</p>
      <footer class="blockquote-footer">
        <small>
          Someone famous in <cite title="Source Title">Source Title</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card text-center">
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">This card has a regular title and short paragraphy of text below it.</p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
    </div>
  </div>
  <div class="card">

  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Someone famous in <cite title="Source Title">Source Title</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">This is another card with title and supporting text below. This card has some additional content to make it slightly taller overall.</p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
    </div>
  </div>
</div> */}
                    {/* <div className="main-post textingCon animate__animated">
                    <div className="three-rw row">
                    {props.data?.map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                        return (
                            <div className="news-item col-md-12 col-lg-4">
                                    <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                                    newsImg={`${news.Tile_Image.url}`}
                                    schedule = {NewsDate(news.Date)}
                                    newsTitle = {news.Title}
                                    id={news.id}
                                    processedImages={processedImages}
                                    img="property-news.Tile_Image.tile_2"
                                    /></Link>
                            </div>
                        )
                    })}
                </div>


                </div> */}
                        <Subscribe />
                    </Col>
                </Row>
            </Container>

            {/* <Container>
        <Row>
        <Col lg={12}>
        {groups.map((item, i) => {
            return (
            <>
            {i % 2 == 0 ?
            <>
            {item.length >= 3 ? <>
                <div className="main-post textingCon animate__animated">
                    {item.slice(0,1).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                        return (
                        <div className="main-item news-item col-md-12 col-lg-8 video-post-main">
                            <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                            newsImg={`${news.Tile_Image.url}`}
                            schedule = {NewsDate(news.Date)}
                            newsTitle = {news.Title}
                            id={news.id}
                            processedImages={processedImages}
                            img="property-news.Tile_Image.tile_1"
                            /></Link>
                        </div>
                        )
                    })}

                    <div className="vertical-item d-lg-block col-md-12 col-lg-4 video-post-sub">
                    {item.slice(1,3).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                        return (
                        <div className="news-item">
                            <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                            newsImg={`${news.Tile_Image.url}`}
                            schedule = {NewsDate(news.Date)}
                            newsTitle = {news.Title}
                            id={news.id}
                            processedImages={processedImages}
                            img="property-news.Tile_Image.tile_2"
                            /></Link>
                        </div>
                        )
                    })}
                    </div>
                </div>

                <div className="three-rw row">
                    {item.slice(3,6).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                        return (
                            <div className="news-item col-md-12 col-lg-4">
                                    <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                                    newsImg={`${news.Tile_Image.url}`}
                                    schedule = {NewsDate(news.Date)}
                                    newsTitle = {news.Title}
                                    id={news.id}
                                    processedImages={processedImages}
                                    img="property-news.Tile_Image.tile_2"
                                    /></Link>
                            </div>
                        )
                    })}
                </div>
            </> :
                <div className="three-rw justify-content-between">
                    <Row>
                    {item.slice(0,3).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                        return (
                        <Col lg={4}>
                            <div className="news-item news-list-row-3">
                                    <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                                    newsImg={`${news.Tile_Image.url}`}
                                    schedule = {NewsDate(news.Date)}
                                    newsTitle = {news.Title}
                                    id={news.id}
                                    processedImages={processedImages}
                                    img="property-news.Tile_Image.tile_2"
                                    /></Link>
                            </div>
                        </Col>
                        )
                    })}
                    </Row>
                </div>
            }


                {i === 0 || i % 2 == 0 ? <Subscribe /> : ''}
            </>
            : <>
            {item.length >= 3 ? <>
            <div className="main-post2">
                 <div className="vertical-item d-lg-block col-md-12 col-lg-4 video-post-sub-1">
                    {item.slice(0,2).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                        return (
                        <div className="news-item">
                            <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                            newsImg={`${news.Tile_Image.url}`}
                            schedule = {NewsDate(news.Date)}
                            newsTitle = {news.Title}
                            id={news.id}
                            processedImages={processedImages}
                            img="property-news.Tile_Image.tile_2"
                            /></Link>
                        </div>
                        )
                    })}
                </div>

                {item.slice(2,3).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                    return (
                        <div className="main-item news-item col-md-12 col-lg-8 video-post-main-1">
                            <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                            newsImg={`${news.Tile_Image.url}`}
                            schedule = {NewsDate(news.Date)}
                            newsTitle = {news.Title}
                            id={news.id}
                            processedImages={processedImages}
                            img="property-news.Tile_Image.tile_1"
                            /></Link>
                        </div>
                    )
                })}
            </div>

            <div className="three-rw row">
                {item.slice(3,6).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                    return (
                        <div className="news-item col-md-12 col-lg-4">
                            <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                            newsImg={`${news.Tile_Image.url}`}
                            schedule = {NewsDate(news.Date)}
                            newsTitle = {news.Title}
                            id={news.id}
                            processedImages={processedImages}
                            img="property-news.Tile_Image.tile_2"
                            /></Link>
                        </div>
                    )
                })}
            </div></> :
            <div className="three-rw justify-content-between">
                <Row>
                {item.slice(0,3).map((news, i) => {

                    const image_url = news.Tile_Image.url;

                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                    return (
                        <Col lg={4}>
                        <div className="news-item news-list-row-3">
                            <Link to={props.tag === 'parent' ? 'news/'+news.URL : news.URL}><NewsCard
                            newsImg={`${news.Tile_Image.url}`}
                            schedule = {NewsDate(news.Date)}
                            newsTitle = {news.Title}
                            id={news.id}
                            processedImages={processedImages}
                            img="property-news.Tile_Image.tile_2"
                            /></Link>
                        </div>
                        </Col>
                    )
                })}
                </Row>
            </div> }
            </>
            }
            </>
            )
        })}

        </Col>
        </Row>
    </Container> */}
        </div>
    )
}

export default NewsPost;
