import React, { useState, useEffect } from "react"

import { Form, Button } from "react-bootstrap"
import $ from "jquery"
import { getUser } from "@myaccountServices/store/utils"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { useQuery } from '@apollo/client';
import { postFormData } from "./api/Api"
import _ from "lodash"
import axios from "axios"
import loadable from '@loadable/component'
import * as qs from "query-string"
import gql from "graphql-tag"
import { Link } from "@StarberryUtils"

const GET_OFFICE = gql`
  query GetOffice($postcode: String!){   
    offices(where:{Postcode_contains:$postcode}) {
      Email
    }
  }
`;

const OtherComponent = loadable(() => import('./elements/address-search'))

function MgVipForm(props) {
  const userData = getUser()
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)
  const [adminmail, setAdminMail] = useState("")

  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")

  const [entype, setEnType] = useState("")

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  useEffect(() => {

    setAddressBuildingName(sessionStorage.getItem('addressBuildingName') != null ? sessionStorage.getItem('addressBuildingName') : '')
    setAddressBuildingNumber(sessionStorage.getItem('addressBuildingNumber') != null ? sessionStorage.getItem('addressBuildingNumber') : '')
    setAddressPostalCode(sessionStorage.getItem('addressPostalCode') != null ? sessionStorage.getItem('addressPostalCode') : '')
    setAddressSubBuilding(sessionStorage.getItem('addressSubBuilding') != null ? sessionStorage.getItem('addressSubBuilding') : '')
    setAddressStreet(sessionStorage.getItem('addressStreet') != null ? sessionStorage.getItem('addressStreet') : '')


    
    $("input[name=addressPostalCode]").val('');
    $("input[name=address]").val('');

  }, []);


  const [addressLabel, setAddressLabel] = useState('')
  const [addressBuildingName, setAddressBuildingName] = useState('')
  const [addressSecondaryStreet, setAddressSecondaryStreet] = useState('')
  const [addressBuildingNumber, setAddressBuildingNumber] = useState('')
  const [addressPostalCode, setAddressPostalCode] = useState('')
  const [addressSubBuilding, setAddressSubBuilding] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [postCodeVal, setPostCodeVal] = useState('')
  const [officeMail, setOfficeMail] = useState();


  const hanldePostCode = (value) =>{
    
    setPostCodeVal(value.PostalCode)
    
  }

 

  var postcodeSplit = postCodeVal.split(" ").shift()
  const { loading, error, data } = useQuery(GET_OFFICE, { variables: { postcode: postcodeSplit } });
  
  const fields = [
    {
      element: "config",
      formname: "MgVipForm",
      error_text: "Highlighted fields are required | invalid",
      success_text:
        "Thank you for your interest. A member of our team will contact you shortly.",
      email_temp_user: "mg_vip_form_user",
      email_temp_admin: "mg_vip_form_admin",
      email_server_func: "mgvip",
      event_tracking: "MgVipForm",
      page_url: "/mgvip",
    },
    {
      grpmd: "12",
      label: "Full Name",
      placeholder: "Full Name *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
      defaultValue: userData?.name || ``,
    },

    {
      grpmd: "12",
      label: "Current Address",
      placeholder: "Current Address *",
      name: "address",
      type: "text",
      element: "address-search",
      required: true,
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Phone Number",
      placeholder: "Phone Number *",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
      defaultValue: userData?.mobile || ``,
    },
    {
      grpmd: "",
      label: "Email",
      placeholder: "Email *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
      defaultValue: userData?.email || ``,
    },

    {
      text: "<p>Tell us where you want to live & describe your dream home (eg what sort of property, number of bedrooms you need and your 'must haves')</p>",
      element: "html",
      class: "looking-to-buy",
    },

    {
      grpmd: "12",
      label: "Property Type",
      placeholder: "Property Type *",
      name: "type",
      element: "input",
      required: true,
      labelClass: "content_b-18",
      class: "mb-4",
    },
    {
      grpmd: "12",
      label: "Bedrooms",
      placeholder: "Bedrooms *",
      name: "bedroom",
      element: "input",
      required: true,
      labelClass: "content_b-18",
      class: "mb-4",
    },
    {
      grpmd: "12",
      label: "Area",
      placeholder: "Please specify your areas of interest e.g. Woodside Park, Crouch End. Alternatively, give a postcode eg N21 or N6.",
      name: "area",
      element: "textarea",
      required: true,
      rows:"2",
      class: "mb-4 textarea_field_vip",
    },
    {
      grpmd: "12",
      label: "Outside Space",
      placeholder: "Outside Space *",
      name: "space",
      element: "input",
      required: true,
      labelClass: "content_b-18",
      class: "mb-4",
    },
    {
      grpmd: "12",
      label: "Parking",
      placeholder: "Parking *",
      name: "parking",
      element: "input",
      required: true,
      labelClass: "content_b-18",
      class: "mb-4",
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "btn",
      formclass: "",
    },
    {
      text: 'By clicking enquire I agree to the Martyn Gerrard <a href="/terms-and-conditions/" className="content-link">Terms & Conditions</a>',
      element: "html",
      class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m terms",
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    $("#validationenquiry").change(function () {
      setEnType($("#validationenquiry").val())
    })

    if (token !== "") {
      const processFromData = async () => {
        let formData = new FormData()
        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name,
        })
        formData.append("data", JSON.stringify(formvalues))

        postFormData(formData).then(apiRes => {
          // lets send mail
          const axiosOptions_email = {
            url: "/.netlify/functions/server/" + fields[0].email_server_func,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formvalues),
          }

          axios(axiosOptions_email)
            .then(response => {
              console.log("mail sent!")
            })
            .catch(err => console.log(err))
        })

        formvalues["g-recaptcha-response"] = token

        const url = typeof window !== "undefined" ? window.location.href : ""
        fields[0].formname =
          props.source == "person" ? "Contact Person" : "Contact"
        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: fields[0].formname,
          formLabel: fields[0].formname,
        })

        setShowerror(false)
        setThankyou(true)

        myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
      }
      processFromData()
    }

    let params = new URL(document.location).searchParams
    setAdminMail(params.get("mail"))
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    const form = event.currentTarget

    setAddressBuildingName(sessionStorage.getItem('addressBuildingName') != null ? sessionStorage.getItem('addressBuildingName') : '')
    setAddressBuildingNumber(sessionStorage.getItem('addressBuildingNumber') != null ? sessionStorage.getItem('addressBuildingNumber') : '')
    setAddressPostalCode(sessionStorage.getItem('addressPostalCode') != null ? sessionStorage.getItem('addressPostalCode') : '')
    setAddressSubBuilding(sessionStorage.getItem('addressSubBuilding') != null ? sessionStorage.getItem('addressSubBuilding') : '')
    setAddressStreet(sessionStorage.getItem('addressStreet') != null ? sessionStorage.getItem('addressStreet') : '')

    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )
      if(postcodeSplit?.length > 0){
        json["office_email_admin"] = data?.offices?.length > 0 ? data?.offices[0].Email : "HO@maryngerrard.co.uk"
      }

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token
      json["extra"] = _.omit(json, ["formname", "name", "email", "telephone"])
      setFormvalues(json)

      recaptchaRef.current.execute()

      setValidated(false)

      $(".reach-us-form").hide()
      $(".event-title").hide()
      $(".form-extra").show()
      document.getElementById("form-extra-title").innerHTML =
        "Thank you for your interest. A member of our team will contact you shortly."

      // reset form
      const form = event.target
      form.reset()
    }
  }

  return (
    <>
      <div class="form-extra">
        <h1 id="form-extra-title"></h1>
      </div>
      <div className="form stbform valuation-main checking reach-us-form">
        <div ref={myRef} />

        {showerror && (
          <div className="alert-error">
            <p>{fields[0].error_text}</p>
          </div>
        )}

        {showthankyou && (
          <div className="alert-success">
            <p>{fields[0].success_text}</p>
          </div>
        )}

        <Form
          className="reach-us-form"
          name={fields[0].formname}
          action="/thank-you/"
          method="post"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form_name" value={fields[0].formname} />
          <input type="hidden" name="form_type" value={fields[0].form_type} />
          <input
            type="hidden"
            name="to_email_id"
            value={
              props.to_email_id ? props.to_email_id : adminmail ? adminmail : ""
            }
          />
          <input type="hidden" name="office_name" value={props.office_name} />
          <input type="hidden" name="source" value={props.source} />
          <input type="hidden" name="personname" value={props.personname} />
          <input
            type="hidden"
            name="usearea_general"
            value={
              entype == "General"
                ? props.general
                : entype == "Sales"
                ? props.sales
                : entype == "Lettings"
                ? props.letting
                : ""
            }
          />
          <input type="hidden" name="address" value={props.office_name} />
          <input
            type="hidden"
            name="property_type"
            value={entype == "General" ? "Sales" : entype}
          />
          {/* <input type="hidden" name="admin_email_to" value={adminmail} /> */}
          <input type="hidden" name="bot-field" />
          <div className="step-block">
            {!showthankyou && (
              <p className="top_text">
                To qualify as a MG-VIP please give us some information about you
                and your property search. Alternatively, look at an instant
                online valuation of your{" "}
                <Link to="/market-your-property/book-a-valuation/">
                  current home
                </Link>
              </p>
            )}

          <input id="addressLabel" value="" type="hidden" name="addressLabel" />

          <input id="addressBuildingName" value="" type="hidden" name="addressBuildingName" />

          <input id="addressSecondaryStreet" type="hidden" name="addressSecondaryStreet" />

          <input id="addressBuildingNumber" value="" type="hidden" name="addressBuildingNumber" />

          <input id="addressPostalCode" value={addressPostalCode} type="hidden" name="addressPostalCode" />

          <input id="addressSubBuilding" value="" type="hidden" name="addressSubBuilding" />

          <input id="addressStreet" value="" type="hidden" name="addressStreet" />
           
            
            {fields.map((field, index) => {
              if ("input" === field.element) {
                return (
                  <InputField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    type={field.type}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    labelClass={field.labelClass}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    pattern={field.patternchk}
                    handlechange={handlechange}
                    defaultValue={field.defaultValue}
                  />
                )
              }
              if ("address-search" === field.element) {
                return(
                  <div className="address-search">
                    <OtherComponent hanldePostCode={hanldePostCode} addressLabel={addressLabel} componentType="Vip_Page" />
                  </div>
                )
              }
              if ("select" === field.element) {
                return (
                  <SelectField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    required={field.required}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    values={field.values}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    componentprops={props}
                  />
                )
              }
              if ("textarea" === field.element) {
                return (
                  <TextAreaField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                  />
                )
              }
              if ("checkbox" === field.element) {
                return (
                  <CheckboxField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={handlechange}
                  />
                )
              }
              if ("html" === field.element) {
                return (
                  <HtmlBox
                    text={field.text}
                    fieldClass={field.class}
                    key={`${field.element}~${index}`}
                  />
                )
              }
              if ("captcha" === field.element) {
                return (
                  <ReCaptchaBox
                    fieldClass={field.class}
                    captRef={field.captchaRef}
                    key={`${field.element}~${index}`}
                    handleonVerify={handleonVerify}
                  />
                )
              }
              if ("button" === field.element) {
                return (
                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    formclass={field.formclass}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                  />
                )
              }
            })}
          </div>
        </Form>
      </div>
    </>
  )
}

const MgVipFormPage = props => <MgVipForm />

export default MgVipFormPage
