import React,{useState, useEffect} from 'react'
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";

import VideosHead from "./videos-head"
import VideoPost from "../News/NewsPost/VideoPost"
import FilterCareerVideos from "./filter-career-videos"


const VideoLanding = () =>{

  const [ search_text, setSearchText ] = useState('');

  const handlechange = (event) => {
	event.preventDefault();
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);

  }

    const data = useStaticQuery(graphql`
    query{
      allStrapiVideos(filter: {Publish: {eq: true}, Videos_Parent_Category: {URL: {ne: "property-guide-videos"}}}, sort: {order: DESC, fields: Date}) {
	    edges {
	      node {
	        URL
	        Title
	        Date
			Content
			description
	        Tile_Image {
	          internal {
	            description
	          }
	        }
	        Embed_Video_URL
	        Video_Category {
	          Build_Separate_Page
	          Choose_Category_Base_URL
	          URL
	        }
	        Videos_Parent_Category {
	          URL
	          Name
	        }
	        strapiId
          imagetransforms {
            Tile_Image_Transforms
          }
	      }
	    }
	  }

	  allStrapiAllMenus {
	    edges {
	      node {
	        URL
	        Alias
	        Sub_Parent {
	          Alias
	          URL
	          id
	        }
	        Main_Parent {
	          Alias
	          URL
	          id
	        }
	        Link_Type
	        id
	        strapiId
	      }
	    }
	  }


    }
  `);

var videos = [];
// debugger
for(var i=0; i < data.allStrapiVideos.edges.length; i++){
	videos.push(data.allStrapiVideos.edges[i].node)
}

  	return (
  		<>

		<div className="news-page video-main-landing">
		    <VideosHead tag="All Videos" text={search_text} handlechange={handlechange}/>
		    {videos.length == 0 ?
		    	<p className="no-video">No videos found, please check after some times</p> : ''
		    }

		    {search_text != '' ? <FilterCareerVideos text={search_text} tag="videos" data={videos}  menus={data.allStrapiAllMenus.edges}/> :
		    <VideoPost data={videos} tag="parent" menus={data.allStrapiAllMenus.edges}/> }

	    </div>

  		</>
  	)
}



export default VideoLanding
